import { toast } from "react-toastify";

const useAlertBar = () => {
  const openAlertBar = (alertMsg: string | JSX.Element, isSuccess: boolean) => {
    if (isSuccess) {
      toast.success(alertMsg, { autoClose: 3000 });
    } else {
      toast.error(alertMsg);
    }
  };

  return { openAlertBar };
};

export default useAlertBar;
