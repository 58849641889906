import React, { useState } from "react";
import {
  Carousel,
  CarouselIndicators,
  CarouselControl,
  CarouselItem,
  Col,
  Row,
} from "reactstrap";
import Link from "next/link";
import Image from "next/image";

import classes from "@/styles/auth/sign-up.module.css";

const items = [
  {
    blogs: [
      {
        title: "Shipping from Canada to Ireland",
        link: "https://shipvista.com/blog/shipping-from-canada-to-ireland",
        src: "https://cbpstaticfiles.blob.core.windows.net/sv-app/blog-images/page-44.png",
      },
      {
        title: "Shipping from Canada to Poland",
        link: "https://shipvista.com/blog/shipping-from-canada-to-poland",
        src: "https://cbpstaticfiles.blob.core.windows.net/sv-app/blog-images/page38.png",
      },
      {
        title: "Shipping from Canada to the Czech Republic",
        link: "https://shipvista.com/blog/shipping-from-canada-to-the-czech-republic",
        src: "https://cbpstaticfiles.blob.core.windows.net/sv-app/blog-images/page-37.png",
      },
    ],
    altText: "Ship International with ShipVista.com",
    caption: "Ship International with ShipVista.com",
    key: 1,
  },
  {
    blogs: [
      {
        title: "Shipping from Canada to Italy",
        link: "https://shipvista.com/blog/shipping-from-canada-to-italy",
        src: "https://cbpstaticfiles.blob.core.windows.net/sv-app/blog-images/page-36.png",
      },
      {
        title: "Shipping from Canada to Sweden",
        link: "https://shipvista.com/blog/shipping-from-canada-to-sweden",
        src: "https://cbpstaticfiles.blob.core.windows.net/sv-app/blog-images/page-9.png",
      },
      {
        title: "Shipping from Canada to the Czech Argentina",
        link: "https://shipvista.com/blog/shipping-from-canada-to-argentina",
        src: "https://cbpstaticfiles.blob.core.windows.net/sv-app/blog-images/page-2.png",
      },
    ],
    altText: "Ship International with ShipVista.com",
    caption: "Ship International with ShipVista.com",
    key: 2,
  },
  {
    blogs: [
      {
        title: "Shipping from Canada to Egypt",
        link: "https://shipvista.com/blog/shipping-from-canada-to-egypt",
        src: "https://cbpstaticfiles.blob.core.windows.net/sv-app/blog-images/page-27.png",
      },
      {
        title: "Shipping from Canada to Lativa",
        link: "https://shipvista.com/blog/shipping-from-canada-to-lative",
        src: "https://cbpstaticfiles.blob.core.windows.net/sv-app/blog-images/page-26.png",
      },
      {
        title: "Shipping from Canada to the Czech Greece",
        link: "https://shipvista.com/blog/shipping-from-canada-to-the-czech-greece",
        src: "https://cbpstaticfiles.blob.core.windows.net/sv-app/blog-images/page-25.png",
      },
    ],
    altText: "Ship International with ShipVista.com",
    caption: "Ship International with ShipVista.com",
    key: 3,
  },
];

const BlogCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const next = () => {
    if (animating) return;

    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;

    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex: any) => {
    if (animating) return;

    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.altText}
        className="h-100"
      >
        <div className="w-75 mx-auto">
          <Row>
            {item.blogs.map((b, i) => (
              <Col className="p-3 text-center" key={i} xs={12} lg={4}>
                <Image src={b.src} alt={b.src} height={70} width={100} />
                <Link
                  className={`d-block text-white ${classes.caLink}`}
                  href={b.link}
                >
                  {b.title}
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      </CarouselItem>
    );
  });
  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous}>
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
        className={classes.indicators}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
};

export default BlogCarousel;
