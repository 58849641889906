import shipvistaAPI from "./shipvistaAPI";

export const registerService = (data: any) => {
  return shipvistaAPI.post("/register", data);
};

export const loginService = (loginData: any) => {
  return shipvistaAPI.post("/Login", loginData);
};

export const forgotPasswordService = (forgotPasswordData: any) => {
  return shipvistaAPI.post(`/Login/forgot-password`, forgotPasswordData);
};

export const updateForgotPasswordService = (updateForgotPasswordData: any) => {
  return shipvistaAPI.post(
    `/Login/update-forgot-password`,
    updateForgotPasswordData
  );
};

export const verifyForgotPasswordService = (verificationCode: string) => {
  return shipvistaAPI.get(
    `/Login/verify-forgot-password?token=${verificationCode}`
  );
};

export const resendCodeService = (data: any) => {
  return shipvistaAPI.post(`/register/resendcode`, data);
};

export const verifySecureCode = (verificationDetails: {
  secureCode: string;
  email: string;
}) => {
  return shipvistaAPI.post(`/register/verifycode`, verificationDetails);
};
