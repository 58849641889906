import Axios from "axios";

import { shipvistaApiURL } from "@/utils/constants";

const shipvistaAPI = Axios.create({
  baseURL: shipvistaApiURL,
  headers: {},
});

// shipvistaAPI.CancelToken = Axios.CancelToken;

// const accessToken = localStorage.getItem("okta-token-storage");

// if (accessToken) {
//   const parseToken = JSON.parse(accessToken);
//   shipvistaAPI.defaults.headers.common["Authorization"] =
//     "Bearer " + parseToken.accessToken.accessToken;

//   const username = parseToken.idToken
//     ? parseToken.idToken.claims.preferred_username
//     : "";
//   if (username) {
//     localStorage.setItem("currentUser", username.split("@")[0]);
//   }
// } else {
//   delete shipvistaAPI.defaults.headers.common["Authorization"];
// }

export default shipvistaAPI;
