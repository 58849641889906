import classnames from "classnames";
import { FormGroup, Label } from "reactstrap";

type Option = {
  value: string;
  label: string;
};

type Props = {
  placeholder?: string;
  label: string;
  error?: string;
  type?: string;
  name: string;
  disabled?: boolean;
  register: any;
  required?: boolean;
  inputSize?: "sm";
  options?: Option[];
};

const InputGroup = ({
  type = "text",
  placeholder,
  register,
  label,
  disabled,
  error,
  name,
  required,
  inputSize,
  options,
}: Props) => {
  return (
    <FormGroup className="mb-1">
      <Label for={name}>
        {label}
        {required && <span className="text-danger"> *</span>}
      </Label>

      {type === "select" && options ? (
        <select
          className={classnames("form-control", {
            "form-control-sm": inputSize === "sm",
            "is-invalid": error,
          })}
          disabled={disabled}
          {...register(name)}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : (
        <input
          type={type}
          className={classnames("form-control", {
            "form-control-sm": inputSize === "sm",
            "is-invalid": error,
          })}
          placeholder={placeholder}
          disabled={disabled}
          {...register(name)}
        />
      )}

      {error && <div className="invalid-feedback">{error}</div>}
    </FormGroup>
  );
};

export default InputGroup;
